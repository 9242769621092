import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './views/Layout/Layout';
import Slots from './views/Slots/Slots';
import Casino from './views/Casino/Casino';
import Promotion from './views/promotions/Promotion';
import Disclaimer from './views/Disclaimer/Disclaimer';

function App() {

  return (
    <BrowserRouter >
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route path='/' element={<Casino />} />
          <Route path='/Promotions' element={<Promotion />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/slots' element={<Slots />} />
 </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
