import React from "react";
import { useTranslation } from "react-i18next";

export default function Containt() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900">
        <div className="html-content mx-auto max-w-7xl p-4 xl:px-0">
          <h1 className="font-bold text-3xl">
            {t("AceLuckyCasinoReviewAComprehensiveLookintoaPremierOnlineGamingDestination")}
          </h1>
          <h2 className="font-bold mt-4"> {t("IntroductiontoAceLuckyCasino")}
          </h2>
          <p> {t("WelcometoourindepthanalysisofAceLuckyCasinoaleadingnameintheonlinegamblingindustryRenownedforitsvastarrayofgamescuttingedgetechnologyandexceptionalcustomerserviceAceLuckyCasinostandsasabeaconforavidgamersandnewcomersalike")}
          </p>
          <h2 className="font-bold mt-4"> {t("ExtensiveGameSelectionatAceLuckyCasino")}
          </h2>
          <p className=" mt-4">{t("ExploreaWorldofGamingAceLuckyCasinoboastsanimpressivecollectionofgamescateringtodiversetastesandpreferencesFromclassicslotstoadvancedvideoslotstablegameslikeblackjackandroulettetolivedealerexperiencesthecasinoensuresathrillingandvariedgamingjourneyforeveryplayer")}
          </p>

          <p className="font-bold mt-4">{t("SlotsGalore")}
          </p>
          <ul>
            <li> {t("ClassicandVideoSlotsDiveintoaseaofspinningreelswithamyriadofthemesandfeaturesFromtraditionalfruitmachinestomodernstorydrivenvideoslotstheresagameforeveryslotenthusiast")}
            </li>
            <li>{t("ProgressiveJackpotsChaselifechangingwinswithprogressivejackpotslotsThesegamesoffertheexcitementofastandardslotgameplustheaddedthrillofagrowingjackpotpool")}
            </li>

          </ul>

          <p className="font-bold mt-4">
            {t("TableGamesandLiveCasino")}
          </p>
          <ul>
            <li>  {t(" BlackjackRouletteandMoreIndulgeintheclassicswithavarietyofblackjackandroulettegamesWhetheryouprefertheAmericanorEuropeanversionsAceLuckyCasinohasyoucovered")}
            </li>
            <li> {t("LiveDealerExperiencesElevateyourgamingwithlivedealergamesRealdealersrealtimeactionandarealcasinoatmosphereallfromthecomfortofyourhome")}
            </li>
          </ul>

          <h2 className="font-bold mt-4">
            {t("AdvancedTechnologyandUserExperience")}</h2>
          <p className=" mt-4"> {t("SeamlessGamingonAnyDeviceAceLuckyCasinoutilisesthelatesttechnologytoensureaflawlessgamingexperienceacrossalldevicesWhetheryoureplayingonadesktoptabletorsmartphonethecasinosinterfaceisintuitiveandresponsive")}
          </p>
          <p className="font-bold mt-4">
            {t("SecurityandFairness")}</p>
          <ul>
            <li>
              {t("TopNotchSecurityMeasuresYoursafetyisparamountThecasinoemploysadvancedencryptiontechnologiestoprotectyourpersonalandfinancialinformation")}
            </li>
            <li>  {t("FairPlayGuaranteedGamesatAceLuckyCasinoareregularlyauditedforfairnessbyindependentbodiesensuringanhonestandtransparentgamingenvironment")}
            </li>
          </ul>

          <h2 className="font-bold mt-4"> {t("StellarCustomerSupport")}
          </h2>
          <p> {t("AroundtheClockAssistanceThecasinopridesitselfonofferingexemplarycustomerserviceAdedicatedteamisavailabletoansweranyqueriesandresolveanyissuesensuringasmoothandenjoyablegamingexperience")}
          </p>

          <h2 className="font-bold mt-4">{t("BonusesandPromotions")}
          </h2>
          <p>{t("GenerousOffersforPlayersAceLuckyCasinodelightsplayerswithavarietyofbonusesandpromotionsFromwelcomebonusestoregularpromotionsthereareplentyofopportunitiestoboostyourgameplay")}
          </p>

          <h2 className="font-bold mt-4">   {t("ResponsibleGaming")}
          </h2>
          <p>{t("CommitmenttoPlayerWellbeingThecasinoisdedicatedtopromotingresponsiblegamingWithtoolsandresourcestohelpmanageyourgamingAceLuckyCasinoensuresasafeandcontrolledenvironmentforallplayers")}
          </p>

          <h2 className="font-bold mt-4">  {t("Conclusion")}
          </h2>
          <p>   {t("AceLuckyCasinostandsoutasatopdestinationforonlinegamingenthusiastsWithitsvastselectionofgamesadvancedtechnologyandcommitmenttoplayersatisfactionitoffersanunmatchedgamingexperience")}
          </p>

        </div>
        <div
          className="absolute top-0 -z-5 -translate-x-10 blur-3xl xl:top-20"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#a3e635] to-[#a3e635] opacity-15"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
