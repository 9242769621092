
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./messages/en.json"
import fr from "./messages/fr.json"
import es from "./messages/es.json"
import pt from "./messages/pt.json"
import ja from "./messages/ja.json"



i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: en,
    },
    fr: {
      translations: fr,
    },
    es: {
      translations: es,
    },
    pt: {
      translations: pt,
    },
    ja: {
      translations: ja,
    },
  },
  lng: "en",
  fallbackLng: "en",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
