import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CasinoSlider = (props) => {
  const [sportsData, setSportsData] = useState([]);

  useEffect(() => {
    if (props.sportsData.length > 0) {
      setSportsData(props.sportsData);
    }
  }, [props]);

  const options = {
    items: 5,
    rows: 2,
    loop: true,
    speed: 100,
    autoplay: true,
    dots: false,
    margin: 20,
    responsive: {
      0: { items: 2, rows: 2 },
      480: { items: 3, rows: 2 },
      768: { items: 4, rows: 2 },
      1024: { items: 5, rows: 2 },
      1200: { items: 6, rows: 2 },
    },
  };

  return (
    <>
      {sportsData && sportsData.length ? (
        <OwlCarousel className="owl-theme" {...options}>
          {sportsData?.map((item, key) => (
            <li
              key={key}
              className="carousel__slide carousel__slide--visible carousel__slide--active item"
              aria-hidden="false"
            >
              <Link
                to="https://www.aceluckycasino.com/#m/login"
                className="relative mx-1 rounded-lg overflow-hidden transform transition-transform duration-300 h-44 md:h-full hover:scale-105 z-30"
              >
                <div
                  className={`absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-${
                    [
                      "blue-600",
                      "green-600",
                      "red-600",
                      "yellow-600",
                      "purple-600",
                      "teal-600",
                      "pink-600",
                    ][Math.floor(Math.random() * 7)]
                  }`}
                />
                {item?.imageUrl && (
                  <img
                    src={item?.imageUrl}
                    alt="Starburst Slot"
                    title="Starburst"
                    className="rounded-lg h-full"
                  />
                )}
                <div className="absolute bottom-0 left-0 w-full text-center z-20 mb-2 px-2">
                  <h2 className="font-bold tracking-tight text-sm text-white mb-1">
                    {item?.gameName ? item?.gameName : "-"}
                  </h2>
                </div>
              </Link>
            </li>
          ))}
        </OwlCarousel>
      ) : null}
    </>
  );
};

export default CasinoSlider;
