import React from "react";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

export default function Disclaimer() {
  const { t, i18n } = useTranslation();
  return (
    <div className="relative isolate overflow-hidden bg-gray-900">

      
      <div className="html-content mx-auto max-w-7xl p-4 xl:px-0">
        <h1 className="font-bold text-2xl">
        {t("Disclaimer")}</h1>
        <hr />

        <p>
        {t("AceLuckyCasinoCasinooperatesunderstrictregulatoryguidelinesandadherestoalllegalrequirementsofitsjurisdictionItistheresponsibilityofeachplayertoensurethattheyareactingwithinthelegalframeworkoftheirrespectivecountryorregionwhenaccessingourcasinoplatform")}
         
        </p>

        <p>
        {t("AceLuckyCasinoCasinodoesnotacceptplayersfromcountriesorregionswhereonlinegamblingisprohibitedorregulatedbylawsthatconflictwithourtermsofservicePlayersareadvisedtoverifythelegalstatusofonlinegamblingintheirjurisdictionbeforeparticipatinginanycasinoactivities")}
        </p>
        {/* <hr /> */}
        <p>
        {t("AceLuckyCasinoCasinoshallnotbeheldliableforanylegalviolationsorconsequencesfacedbyplayerswhoaccessourservicesfromjurisdictionswheresuchactivitiesarenotpermittedItisthesoleresponsibilityoftheplayertounderstandandcomplywithanylawsorregulationstowhichtheyaresubjectregardingonlinegambling")}
         
        </p>
        <p>
        {t("ThiswebsiteanditscontentareprovidedonanasisbasiscompletenessoftheinformationcontainedonthiswebsiteThecasinoshallnotbeliableforanydamagesorlossesarisingfromtheuseorinabilitytousethissiteoritscontents")}

          
        </p>
        <p>
        {t("PlayersshouldbeawarethatgamblinginvolvesriskandshouldengageinitresponsiblyandwithintheirfinancialmeansForfurtherinformationorassistanceregardingresponsiblegamblingpleaserefertoourResponsibleGamingsectionorcontactourcustomersupportteam")}
         
        </p>
        <p>
        {t("AceLuckyCasinoCasinoreservestherighttomodifythesetermsandconditionsatanytimewithoutpriornoticeItistheresponsibilityoftheplayertoregularlyreviewthesetermsandconditionsforanychanges")}
        
        </p>
        <p>
        {t("ByaccessingandusingtheAceLuckyCasinoCasinowebsiteplayersacknowledgeandagreetoabidebythesetermsandconditions")}
         
        </p>
        <p>
        {t("PlayresponsiblyandenjoyyourtimeatAceLuckyCasinoCasino")}
          </p>
      </div>


      <Footer/>
    </div>
  );
}
