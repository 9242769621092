/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import '../../App.css'
import { useTranslation } from "react-i18next";
import stadiumCover from '../../assets/image-asset.jpeg'

export default function ProductInfo() {
  const { t, i18n } = useTranslation();
  return (
    <div>
       <div className="py-10 md:py-11 relative">
                <div className="absolute -top-0 left-0 -z-9 h-full w-full blur-3x1  bg-gradient-to-tr from-[#000000] to-[#000000] opacity-40" />
                <img src={stadiumCover}  className="absolute inset-0 -z-10 h-full w-full object-cover object-center md:object-center" />
                
                <div className="mx-auto max-w-7xl relative ">
                  <div className='  '>

                  <div className=' grid grid-row-2 text-center gap-y-5 p-4 '>
                    <dd className='text-[45px]'>4000+</dd>
                    <dt className='text-[15px] font-light'>
                    {t("Playerscanchoosefromanextensivecatalogueofmorethangamesatthecasino")}</dt> 
                  </div>
                  {/* <div className=' grid grid-row-2 text-center gap-y-5 p-4 '>
                    <dd className='text-[45px]'>30,000+</dd>
                    <dt className='text-[15px] font-light'>
                    {t("Betonsportslivewithover30000inplaybettingeventseachmonth")}
                     </dt> 
                  </div>
                  <div className=' grid grid-row-2 text-center gap-y-5  p-4 '>
                    <dd className='text-[45px]'> 140+</dd>
                    <dt className='text-[15px] font-light'>
                    {t("betonvarietywithover140sportsavailablefromaroundtheworld")}</dt> 
                  </div>
                  <div className=' grid grid-row-2 text-center gap-y-5 p-4 '>
                    <dd className='text-[45px]'> UK &amp; MGA</dd> 
                    <dt className='text-[15px] font-light'> {t("LicencedandregulatedbytheUKGCandtheMaltaGamingAuthority")}
                  
                        </dt> 
                  </div> */}

                </div>
               
                </div>
              </div>
              
    </div>
  )
}

